@use '../../styles/variables' as *;
@use '../../styles/functions' as *;
@use '../../styles/mixins' as *;

@keyframes cursorBlink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.wordlist {
  position: relative;
  display: inline-block;
  font-family: $font-secondary;

  &::after {
    content: '';
    margin: auto;
    position: absolute;
    top: -1px;
    right: -2px;
    width: $grid-gap-phone * 0.1;
    height: 100%;
    background-color: $orange;
    animation: cursorBlink ($transition-slow * 2) step-end infinite;
  
    @include breakpoint-small {
      top: -3px;
      right: -4px;
    }
  }
}