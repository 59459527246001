@import './_variables.scss';

// Common dialog elements
.batch-dialog-title {
  .subtitle {
    margin-top: 4px;
    color: $darkgray;
  }
  
  .success-message {
    margin-top: 4px;
    color: $green;
  }
}

// Common progress indicators
.batch-progress-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.batch-progress-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.batch-linear-progress {
  height: 4px;
  border-radius: 2px;
  
  &.overall {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  
  &.file-progress {
    height: 4px;
    border-radius: 2px;
    
    &.uploading {
      .MuiLinearProgress-bar {
        background-color: $orange;
      }
    }
    
    &.retrying {
      .MuiLinearProgress-bar {
        background-color: $orange-half-pale;
      }
    }
  }
  
  .MuiLinearProgress-bar {
    background-color: $black;
  }
}

// File list container
.file-status-container {
  max-height: 300px;
  overflow: auto;
  margin-top: 16px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid $lightgray;
}

// Status icon container
.status-icon-container {
  display: flex;
  align-items: center;
  margin-right: 12px;
  min-width: 28px;
  justify-content: center;
}

// File list item
.file-list-item {
  position: relative;
  border-radius: 4px;
  margin-bottom: 4px;
  padding: 8px 16px;
  transition: all 0.2s ease;
  
  // Base styles
  &.pending {
    color: $black;
    background-color: transparent;
    
    &:hover {
      background-color: rgba($black, 0.04);
    }
  }
  
  // Error state
  &.error {
    color: $orange;
    background-color: rgba($orange, 0.05);
    
    &:hover {
      background-color: rgba($orange, 0.1);
    }
    
    .MuiListItemText-primary {
      color: $orange;
    }
  }
  
  // Completed state
  &.completed {
    color: $green;
    background-color: rgba($green, 0.05);
    
    &:hover {
      background-color: rgba($green, 0.1);
    }
    
    .MuiListItemText-primary {
      color: $green;
    }
  }
  
  // Skipped/Exists state
  &.skipped, &.exists {
    color: $orange-half-pale;
    background-color: rgba($orange-half-pale, 0.05);
    
    &:hover {
      background-color: rgba($orange-half-pale, 0.1);
    }
    
    .MuiListItemText-primary {
      color: $orange-half-pale;
    }
  }
  
  // Processing/Uploading state
  &.processing, &.uploading, &.retrying {
    color: $black;
    background-color: rgba($blue, 0.05);
    padding-bottom: 24px;
    
    &:hover {
      background-color: rgba($blue, 0.08);
    }
    
    .MuiListItemText-secondary {
      color: $black;
    }
  }
  
  .MuiListItemText-primary {
    font-weight: 500;
  }
  
  .MuiListItemText-secondary {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}

// Progress bar wrapper in list items
.progress-bar-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
  padding-bottom: 4px;
  z-index: 1;
  pointer-events: none;
}

// Status summary badges
.status-summary {
  display: flex;
  gap: 8px;
  
  .status-badge {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

// Error details
.error-details {
  margin-top: 8px;
  padding: 8px;
  background-color: rgba($orange, 0.05);
  border-radius: 4px;
  font-size: 0.875rem;
  color: $orange;
}

// Error summary container
.error-summary {
  margin-top: 16px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid $orange;
  background-color: rgba($orange, 0.05);
}

// Category selection grid
.category-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 16px;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid $lightgray;
}

// Retry all button
.retry-all-button {
  margin-top: 16px;
  background-color: rgba($orange, 0.1);
  color: $orange;
  
  &:hover {
    background-color: rgba($orange, 0.2);
  }
  
  &.Mui-disabled {
    color: rgba($orange, 0.5);
  }
}

// Alert styles
.warning-alert {
  margin-top: 16px;
  background-color: rgba($orange-half-pale, 0.05);
  color: $orange-half-pale;
}

// File input styling
.file-input {
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px;
  border: 1px dashed $lightgray;
  border-radius: 4px;
  width: 100%;
} 