@use '../../../styles/variables' as *;
@use '../../../styles/functions' as *;
@use '../../../styles/mixins' as *;

.addNewUserForm {
  margin-top: $grid-gap-phone;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $grid-gap-phone;
  text-align: center;
  position: relative; // This ensures that any absolutely positioned children are relative to this container.

  h2 {
    font-size: 2.5rem;
    color: $black;
    margin-bottom: 1rem;
  }

  form {
    gap: $grid-gap-phone;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; // Ensure form takes the full container width

    .MuiTextField-root {
      width: 100%; // Ensures that input fields take full width of the form
      margin-bottom: $grid-gap-phone; // Adds spacing between form elements
    }

    .MuiButton-root {
      margin-top: $grid-gap-phone; // Adds spacing above the button
    }
  }

  p {
    font-size: 1.1rem;
    color: $darkgray;
    margin-top: 1rem; // Space after the form or button
  }

  .error {
    color: red
  }

  .success {
    color: green
  }
}

.waitingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($darkgray, 0.5); // Adjust the transparency as needed
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
