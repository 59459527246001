@use '../../styles/variables' as *;
@use '../../styles/functions' as *;
@use '../../styles/mixins' as *;

.italic {
  font-style: italic;
}


@keyframes moveAround {
  0% {
    transform: scale3d(1, 1, 1) translate3d(-30vh, 20vh, 0);
  }

  20% {
    transform: scale3d(0.6, 0.6, 1) translate3d(-20vh, 10vh, 0);
    background: $orange-half-pale;
  }

  40% {
    transform: scale3d(1.2, 1.2, 1) translate3d(20vh, -5vh, 0);
    background: $orange-half-pale;
  }

  60% {
    transform: scale3d(1.4, 2.4, 1) translate3d(20vh, 10vh, 0);
    background: $orange-half-pale;
  }

  80% {
    transform: scale3d(1.6, 1.4, 1) translate3d(-20vh, 30vh, 0);
    background: $orange-half-pale;
  }

  100% {
    transform: scale3d(1, 1, 1) translate3d(-30vh, 20vh, 0);
    background: $orange-half-pale;
  }
}

.hero {
  position: relative;
  width: 100%;
  height: 100svh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: $grid-gap-phone * 0.5;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 50vh;
    height: 50vh;
    border-radius: 100%;
    z-index: $z-index-low;
    user-select: none;
    pointer-events: none;
    background: $orange-half-pale;
    filter: blur($grid-gap-phone * 4);
    animation: moveAround ($transition-slow * 45) $transition-easing infinite alternate;
  }

  h1, h2, h3, a {
    z-index: $z-index-medium;
  }

  h1 {
    font-size: 28px;
    line-height: 32px;
  
    @include breakpoint-small {
      font-size: 48px;
      line-height: 54px;
      letter-spacing: -0.01em;
      word-spacing: -0.01em;
    }
  }

  h2 {
    font-size: 16px;
    line-height: 20px;

    @include breakpoint-small {
      font-size: 28px;
      line-height: 32px;
    }
  
    @include breakpoint-medium {
      width: calc(((100% - 9 * $grid-gap-desktop) / $grid-columns-desktop) * 6);
    }
  }

  a {
    position: absolute;
    bottom: $grid-gap-phone * 0.5;

    svg {
      width: 18px;
      height: 10px;
      stroke: $darkgray;
      stroke-width: 2px;
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: none;
      transition: all $transition-fast $transition-easing;
    }

    @include interaction-hover() {
      color: $black;

      svg {
        stroke: $black;
      }
    }
  }
}