@use '../../styles/variables' as *;
@use '../../styles/functions' as *;
@use '../../styles/mixins' as *;

.partners {
  text-align: center;
  background-color: $lightgray-transparent;

  h2 {
    margin-bottom: $grid-gap-phone;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: $grid-gap-phone;

    li img {
      height: $grid-gap-phone;

      @include breakpoint-small {
        height: $grid-gap-desktop * 2;
      }
    }
  }
  
  @include breakpoint-small {
    h2 {
      margin-bottom: $grid-gap-phone * 2;
    }
  
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: $grid-gap-phone * 3;
    }
  }
}