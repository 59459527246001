@use '../../styles/variables' as *;
@use '../../styles/functions' as *;
@use '../../styles/mixins' as *;

.footer {
  margin-top: auto;
  padding: $grid-gap-phone;
  background-color: $cadet;
  color: $white;

  nav {
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: $grid-gap-phone * 0.5;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        font-size: 12px;
        line-height: 14px;

        &:first-of-type {
          user-select: none;
        }

        a {
          color: $white;
          @include interaction-hover() {
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 2px;
          }
        }
      }
    }
  }
}