@use '../../styles/variables' as *;
@use '../../styles/functions' as *;
@use '../../styles/mixins' as *;

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  gap: $grid-gap-phone;
  z-index: $z-index-high;
  padding: $grid-gap-phone $grid-gap-phone ($grid-gap-phone * 1.25) $grid-gap-phone;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z-index-background;
    background: $lightgray-transparent;
    mask: linear-gradient(to top, transparent, $lightgray $grid-gap-phone * 0.5);
    backdrop-filter: blur($grid-gap-phone * 2);
  }

  @include breakpoint-small {
    flex-direction: column;
  }

  @include breakpoint-medium {
    flex-direction: row;
    height: auto;
  }

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    width: 100%;
  }

  header span,
  nav ul li {
    & > a {
      color: $color-text;

      @include interaction-hover() {
        text-decoration: none;
        color: $darkgray;
      }
    }
  }

  header > span + div {
    display: flex;
    flex-direction: row;
    gap: $grid-gap-phone;

    @include breakpoint-medium {
      display: none;
      user-select: none;
      pointer-events: none;
    }
  }

  header button {
    all: unset;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: $grid-gap-phone;

    @include interaction-hover() {
      cursor: pointer;
    }
  }

  nav {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      gap: ($grid-gap-phone * 2) $grid-gap-phone;
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 28px;
      line-height: 32px;

      li:nth-last-child(1) {
        display: none;
        user-select: none;
        pointer-events: none;
      }

      li:nth-last-child(2) > a {
        padding: $grid-gap-phone * 0.6;
      }
    }

    @include breakpoint-small {
      width: auto;
      height: auto;
      display: unset;

      ul {
        flex-direction: row;
        gap: $grid-gap-desktop;
        font-size: 16px;
        line-height: 16px;

        li:nth-last-child(2) > a {
          padding: $grid-gap-phone * 0.2 $grid-gap-phone * 0.4;
        }
      }
    }

    @include breakpoint-medium {
      ul {
        li:nth-last-child(1) {
          display: list-item;
          user-select: auto;
          pointer-events: auto;
        }
      }
    }
  }

  &:not(.open) {
    nav {
      display: none;
    }
  }

  &.open {
    height: 100%;
  }

  @include breakpoint-small {
    &.open {
      height: auto;
    }
  }

  @include breakpoint-medium {
    header button {
      display: none;
    }

    &:not(.open) {
      nav {
        display: unset;
      }
    }
  }

  .logo, .logo img {
    height: $grid-gap-phone;
  }
}