@use '../../styles/variables' as *;
@use '../../styles/functions' as *;
@use '../../styles/mixins' as *;

.button {
  all: unset;
  min-width: $grid-gap-phone;
  height: $grid-gap-phone;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: $grid-gap-phone * 0.3 $grid-gap-phone * 1.1;
  color: $darkgray;
  background-color: $lightgray;
  border-radius: $grid-gap-phone * 0.4;
  transition: color $transition-fast $transition-easing, background-color $transition-medium $transition-easing;

  > div {
    color: $darkgray;
  }

  &.action {
    color: $white;
    background-color: $orange;

    > div {
      color: $white;
    }
  }

  &.teaser {
    color: $darkgray;
    background-color: $lightgray;

    > div {
      color: $darkgray;
    }
  }

  &.disabled, &.success, &.error {
    user-select: none;
    pointer-events: none;
    background-color: $darkgray;
  }

  &.success {
    color: $green;
    background-color: $green-pale;
  }

  &.error {
    color: $orange;
    background-color: $orange-pale;
  }

  @include interaction-hover() {
    color: $lightgray;
    background-color: $darkgray;
    cursor: pointer;

    &.action {
      color: $orange-pale;
      background-color: $orange;
    }
  }
}