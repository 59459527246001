@use './variables' as *;
@use './functions' as *;
@use './mixins' as *;

.login-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $darkgray-transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $orange;
  font-size: 2rem;
  z-index: 1000; /* Make sure this is above other elements */
}

.pwa-container {
  display: flex;
  flex-direction: row;
  font-family: $font-primary;
  gap: 10px;
  padding: 10px;
  background-color: $white;
  //border: 1px solid $black;
}

.tree-folder-container {
  background-color: white;
  padding: 0;
  max-height: 100%;
  height: 100%;
  border: 1px solid $cadet;
  border-radius: 10px;
}

.file-info-section {
  flex: 3;
  flex-direction: column;
  border: 1px solid $cadet;
  padding: 16px;
  border-radius: 10px;
  background-color: white;
  gap: 10px;
  max-height: 100%;
  overflow-y: auto; /* Adds vertical scrollbar if content overflows */
}

.form {
  margin: 10px -15px;
  height: 97%;
}

.overlay-info {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  user-select: none;
  pointer-events: none;
  padding: 16px;
  color: $darkgray;
  &.relative {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
}

.tree-help-icon {
  position: absolute;
  top: 0;
  right: 0;
  color: $cadet;
  cursor: pointer;
  z-index: 10;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Adjust as needed */
}

.tree-view {
  width: 100%; /* Takes full width */

  overflow-y: auto;
  margin: 0; /* Removes any margins */
  padding: 0; /* Removes any padding */

  .dx-treeview {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .dx-treeview-item-without-checkbox.dx-state-focused {
    //background-color: $orange;
  }

  .dx-treeview-item {
    padding: 5px 2px;
    border-radius: 5px;
    //background-color: turquoise;

    &:hover {
      background-color: $color-selection;
    }
  }
}


html, body {
  height: 100%; /* This makes sure that the 100vh is taking up the full height */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

.pdf-viewer-section {
  //flex: 2;
  border: 1px solid $cadet;
  padding: 1px 16px 16px; /* top, right & left, bottom */
  border-radius: 10px;
  overflow: auto;
  background-color: white;
  height: 100%;
  position: relative;
}

.file-info-section .preview-btn, .nav-btn {
  align-self: flex-start;
  all: unset;
  min-width: $grid-gap-phone;
  height: 1.5*$grid-gap-phone;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: $grid-gap-phone * 0.2 $grid-gap-phone * 0.4;
  color: $darkgray;
  background-color: $lightgray;
  transition: color $transition-fast $transition-easing, background-color $transition-medium $transition-easing;
  border-radius: $grid-gap-phone * 0.4;

  > div {
    color: $darkgray;
  }

  &.action {
    color: $white;
    background-color: $orange;

    > div {
      color: $white;
    }
  }

  &.low-action {
    color: $white;
    background-color: $orange-pale;

    > div {
      color: $white;
    }
  }

  &.teaser {
    color: $darkgray;
    background-color: $lightgray;

    > div {
      color: $darkgray;
    }
  }

  &.disabled, &.success, &.error {
    user-select: none;
    pointer-events: none;
    background-color: $lightgray;
  }

  &.success {
    color: $green;
    background-color: $green-pale;
  }

  &.error {
    color: $orange;
    background-color: $orange-pale;
  }

  @include interaction-hover() {
    color: $lightgray;
    background-color: $darkgray;
    cursor: pointer;

    &.action {
      color: $orange-pale;
      background-color: $orange;
    }
  }
}

.page-navigation {
  //position: -webkit-sticky; /* For Safari */
  //position: sticky; /* Stick to the top when you reach its scroll position */
  top: 0; /* Distance from the top */
  z-index: 1000; /* Ensure it stays on top of other content */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid $lightgray;
  padding-bottom: 16px;
  margin-bottom: 10px;
}

.folder-tree-section {
  flex: 1;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  height: 100%;
  overflow-y: auto; /* Add scroll if content is larger than the container */
}

.file-properties {
  display: flex;
  flex-direction: column;
}

.parse-btn, .anonymize-btn {
  align-self: flex-start;
}

.hints-section {
  margin-top: 16px;
  padding: 10px;
  border-radius: 5px;
}

.hints-section > *:first-child {
  margin-bottom: 6px;
}

.toggle-grid {
  display: grid;
  grid-template-columns: 60px auto auto;
  width: 100%;
  margin: 0 auto; /* Center the grid */
  overflow: auto; /* Add scroll if content is larger than the container */
}

.toggle-list {
  display: contents; /* Let the items lay out in the grid defined by toggle-grid */
}

.toggle-row {
  display: contents; /* This ensures that the items inside are part of the parent grid */
}

.toggle-item {
  display: block; /* Default display */
  text-align: left; /* Align the text to the left */
  padding: 4px 0; /* Add some padding */
  .MuiSwitch-track {
    background-color: $darkgray-transparent !important;
  }
  .MuiSwitch-thumb {
    background-color: $cadet !important;
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    & + .MuiSwitch-track {
      background-color: $cadet !important;
    }
  }
}

.toggle-item-red {
  display: block; /* Default display */
  text-align: left; /* Align the text to the left */
  .MuiSwitch-track {
    background-color: $darkgray-transparent !important;
  }
  .MuiSwitch-thumb {
    background-color: $darkgray !important;
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    & + .MuiSwitch-track {
      background-color: $cadet !important;
    }
  }
}

.toggle-item-orange {
  display: block; /* Default display */
  text-align: left; /* Align the text to the left */
  .MuiSwitch-track {
    background-color: $darkgray-transparent !important;
  }
  .MuiSwitch-thumb {
    background-color: $orange !important;
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    & + .MuiSwitch-track {
      background-color: $cadet !important;
    }
  }
}

/* Additional styling to keep the switch aligned with the text */
.toggle-row > div:first-child {
  text-align: left; /* Align the switch to the left */
}
input[type="radio"] {
  margin-right: 8px; // Adjust as needed for spacing between radio and label
}

.anonymization-level-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  label {
    display: block;
  }
}

.info-icon {
  margin-left: 5px;
}

.annotations-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}

.annotation {
  pointer-events: auto; /* Enable pointer events for annotations */
}

@keyframes colorChange {
  0% { background-color: $orange; }
  50% { background-color: $cadet; }
  100% { background-color: $orange; }
}

.dynamic-progress-color {
  margin-top: 10px;
  animation: colorChange 2s linear infinite;
}
.popup-text {
  background-color: $lightgray;
  z-index: 10;
  opacity: 1;
  padding: 5px;
  border-radius: $grid-gap-phone * 0.4;
  font-size: 0.8rem;
  color: $cadet;
  border: 1px solid $black;
  max-width: 200px;
  word-wrap: break-word;
  text-align: center; // Center text
}

[type="radio"] {
  // Hide the radio button visually but remain accessible
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}

[type="radio"] + label {
  // Style your label as needed
  position: relative;
  padding-left: 25px; // Adjust as needed, should be enough to place the custom radio button
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 16px; // Custom radio button size
    height: 16px;
    border-radius: 50%;
    background: $darkgray-transparent; // Replace with your desired border color
    border: 1px solid $black; // Border for custom radio
  }
}

[type="radio"]:checked + label::after {
  content: '';
  position: absolute;
  left: 3px; // Adjust to center the circle
  top: 50%;
  transform: translateY(-50%);
  width: 10px; // Size of the inner circle
  height: 10px;
  background: $cadet; // Replace with your desired color
  border-radius: 50%;
}
