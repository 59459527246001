@use './variables' as *;
@use './mixins' as *;

@font-face {
  font-family: 'ABC Diatype Regular Web';
  src: url('../assets/fonts/Diatype/ABCDiatype-Regular.otf') format('opentype'),
    url('../assets/fonts/Diatype/ABCDiatype-Regular.woff2') format('woff2'),
    url('../assets/fonts/Diatype/ABCDiatype-Regular.woff') format('woff');
}

@font-face {
  font-family: 'ABC Diatype Bold Web';
  src: url('../assets/fonts/Diatype/ABCDiatype-Bold.otf') format('opentype'),
    url('../assets/fonts/Diatype/ABCDiatype-Bold.woff2') format('woff2'),
    url('../assets/fonts/Diatype/ABCDiatype-Bold.woff') format('woff');
}

body {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $color-text;
  font-feature-settings: 'kern' 1, 'case' 1;
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

::selection {
  color: $color-text;
  background-color: $color-selection;
}

a {
  color: $color-link;
  text-decoration: none;
  transition: color $transition-fast $transition-easing;

  @include interaction-hover() {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
  }
}

p, p *, button, input, input::placeholder, label {
  font-family: $font-primary;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

h1, h2, h3 {
  font-family: $font-primary;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  @include breakpoint-small {
    font-size: 28px;
    line-height: 32px;
  }
}

h2 {
  font-family: $font-primary;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  @include breakpoint-small {
    font-size: 28px;
    line-height: 32px;
  }
}