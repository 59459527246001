@use './variables' as *;

/* Breakpoints */
@mixin breakpoint-extrasmall() {
  @media (min-width: $breakpoint-extrasmall) {
    @content;
  }
}

@mixin breakpoint-small() {
  @media (min-width: $breakpoint-small) {
    @content;
  }
}

@mixin breakpoint-medium() {
  @media (min-width: $breakpoint-medium) {
    @content;
  }
}

@mixin breakpoint-large() {
  @media (min-width: $breakpoint-large) {
    @content;
  }
}

@mixin breakpoint-extralarge() {
  @media (min-width: $breakpoint-extralarge) {
    @content;
  }
}

/* Interactions */
@mixin interaction-hover() {
  @media (any-hover: hover) {
    &:hover {
      @content;
    }
  }
}

/* Accessibility */
@mixin accessibility-contrast() {
  @media (prefers-contrast: no-preference) {
    @content;
  }
}

@mixin accessibility-motion() {
  @media (prefers-reduced-motion: no-preference) {
    @content;
  }
}