@use '../../styles/variables' as *;
@use '../../styles/functions' as *;
@use '../../styles/mixins' as *;

.team {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: $grid-gap-phone $grid-gap-phone;
  align-items: center;
  text-align: center;

  @include breakpoint-small {
    grid-template-columns: repeat($grid-columns-desktop, 1fr);
    gap: $grid-gap-desktop $grid-gap-desktop;
  }

  h2, h2 + p {
    grid-column: 1 / calc(12 + 1);

    @include breakpoint-small {
      grid-column: 3 / calc($grid-columns-desktop - 1);
    }
  }

  h2 + p {
    margin-bottom: $grid-gap-phone;

    @include breakpoint-small {
      margin-bottom: $grid-gap-desktop;
    }
  }

  > div {
    align-self: start;

    img {
      width: 100%;
      aspect-ratio: 1 / 1;
      border-radius: $grid-gap-phone;
      overflow: hidden;
      margin-bottom: $grid-gap-phone * 0.5;

      @include breakpoint-medium {
        width: calc((100vw - 9 * $grid-gap-desktop) / 8);
      }
    }

    a {
      word-break: break-all;
    }

    &:nth-of-type(1) {
      grid-column: 1 / 7;

      @include breakpoint-small {
        grid-column: 4 / calc($grid-columns-desktop - 5);
      }
    }

    &:nth-of-type(2) {
      grid-column: 7 / 13;

      @include breakpoint-small {
        grid-column: 7 / calc($grid-columns-desktop - 3);
      }
    }
  }
}