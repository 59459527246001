@use '../../styles/variables' as *;
@use '../../styles/functions' as *;
@use '../../styles/mixins' as *;

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $grid-gap-phone * 1.4;
  height: $grid-gap-phone * 1.4;
  border-radius: 100%;
  border: 1px solid white;
  background-color: $orange;
  color: white;
  text-align: center;
  user-select: none;
  overflow: hidden;
  transition: color $transition-fast $transition-easing, border $transition-fast $transition-easing, background-color $transition-medium $transition-easing;

  a {
    display: block;
    width: 100%;
    height: 100%;
    color: $darkgray;

    svg {
      width: 100%;
      height: 100%;
      fill: none;
      stroke: $darkgray;
      stroke-width: 1px;
      fill: transparent;
      transition: all $transition-fast $transition-easing;
    }
  }

  @include interaction-hover() {
    border: 1px solid $black;
    background-color: $lightgray;

    a {
      text-decoration: none;
      color: $black;

      svg {
        stroke: $black;
        fill: $black;
      }
    }
  }
}