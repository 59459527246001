@use '../../styles/variables' as *;
@use '../../styles/functions' as *;
@use '../../styles/mixins' as *;

.highlight {
  display: grid;
  grid-template-columns: repeat($grid-columns-phone, 1fr);
  gap: $grid-gap-phone $grid-gap-phone;
  align-items: start;
  background-color: $lightgray-transparent;

  @include breakpoint-small {
    grid-template-columns: repeat($grid-columns-desktop, 1fr);
    gap: $grid-gap-desktop $grid-gap-desktop;

    &.reverse {
      > img {
        grid-column: 7 / calc($grid-columns-desktop);
      }

      > div {
        grid-column: 2 / calc($grid-columns-desktop - 3);
        grid-row: 1;
      }
    }
  }

  > img {
    width: 100%;
    grid-column: 1 / calc($grid-columns-phone + 1);
    aspect-ratio: 1 / 1;
    border-radius: $grid-gap-phone;
    background: $white;

    @include breakpoint-small {
      grid-column: 2 / calc($grid-columns-desktop - 5);
    }

    @include breakpoint-medium {
      margin-top: calc($grid-gap-desktop * -2);
      background: transparent;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: $grid-gap-phone;
    grid-column: 1 / calc($grid-columns-phone + 1);
    align-self: start;

    ul {
      list-style: none inside;

      margin-top: $grid-gap-phone;

      li {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: baseline;
        color: $black;

        &::before {
          content: '';
          font-size: 16px;
          line-height: 16px;
          color: $white;
          background-color: $orange;
          display: inline-flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-right: $grid-gap-phone * 0.5;
          padding: $grid-gap-phone * 0.2;
          border-radius: $grid-gap-phone;
        }

        &:not(:last-of-type) {
          margin-bottom: $grid-gap-phone;
        }
      }
    }

    a {
      margin-top: $grid-gap-phone;

      @include interaction-hover() {
        text-decoration: none;
      }
    }

    @include breakpoint-small {
      grid-column: 5 / calc($grid-columns-desktop);
    }
  }
}