@use '../../styles/variables' as *;
@use '../../styles/functions' as *;
@use '../../styles/mixins' as *;

.clients {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: $grid-gap-phone $grid-gap-phone;
  align-items: center;
  text-align: center;

  @include breakpoint-small {
    grid-template-columns: repeat($grid-columns-desktop, 1fr);
    gap: $grid-gap-desktop $grid-gap-desktop;
  }

  h2, h2 + p {
    grid-column: 1 / calc(12 + 1);
  
    @include breakpoint-small {
      grid-column: 4 / calc($grid-columns-desktop - 2);
    }
  }

  h2 + p {
    margin-bottom: $grid-gap-phone;
  
    @include breakpoint-small {
      margin-bottom: $grid-gap-desktop;
    }
  }

  h4 + p {
    color: $darkgray;
  }

  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: ($grid-gap-phone * 2) $grid-gap-phone;
    align-items: start;
    align-self: start;
    grid-column: 1 / calc(12 + 1);
  
    @include breakpoint-small {
      gap: $grid-gap-desktop $grid-gap-desktop;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column: 2 / calc($grid-columns-desktop);
    }
  
    @include breakpoint-medium {
      grid-column: 3 / calc($grid-columns-desktop - 1);
    }

    > li {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-start;
      text-align: left;
      gap: $grid-gap-phone;
  
      @include breakpoint-small {
        display: list-item;
        text-align: center;
      }

      img {
        width: calc(((100vw - 6 * $grid-gap-phone) / $grid-columns-phone) * 1);
        flex-shrink: 0;
        aspect-ratio: 1 / 1;
        border-radius: $grid-gap-phone * 0.5;
        background-color: $cadet;
        overflow: hidden;
        padding: $grid-gap-phone * 0.5;
  
        @include breakpoint-small {
          width: calc(((100vw - 11 * $grid-gap-desktop) / $grid-columns-desktop) * 2);
          border-radius: $grid-gap-desktop;
          margin-bottom: $grid-gap-desktop * 0.5;
          padding: $grid-gap-desktop;
        }
  
        @include breakpoint-medium {
          width: calc((100vw - 11 * $grid-gap-desktop) / $grid-columns-desktop);
        }
      }

      &:nth-of-type(1) {
        grid-column: 1 / 2;
      }
  
      &:nth-of-type(2) {
        grid-column: 2 / 3;
      }
  
      &:nth-of-type(3) {
        grid-column: 3 / 4;
      }
    }
  }
}