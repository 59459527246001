@use '../../styles/variables' as *;
@use '../../styles/functions' as *;
@use '../../styles/mixins' as *;

.contact {
  display: grid;
  grid-template-columns: repeat($grid-columns-phone, 1fr);
  gap: $grid-gap-phone $grid-gap-phone;
  align-items: start;
  justify-content: space-between;
  background-image: linear-gradient(to bottom, $cadet, $cadet 90%);
  color: $white;

  @include breakpoint-medium {
    grid-template-columns: repeat($grid-columns-desktop, 1fr);
    gap: $grid-gap-desktop $grid-gap-desktop;
  }

  h2 {
    margin-bottom: $grid-gap-phone;
  }

  > div {
    &:nth-of-type(1) {
      grid-column: 1 / calc($grid-columns-phone + 1);
  
      @include breakpoint-medium {
        grid-column: 2 / calc($grid-columns-desktop - 4);
      }
    }

    &:nth-of-type(2) {
      grid-column: 1 / calc($grid-columns-phone + 1);
  
      @include breakpoint-medium {
        grid-column: 7 / calc($grid-columns-desktop);
      }
    }
  }

  .form {
    background: $white;
    padding: $grid-gap-phone;
    border-radius: $grid-gap-phone;

    p {
      color: $darkgray;

      &:first-of-type {
        margin-bottom: $grid-gap-phone;
  
        @include breakpoint-small {
          margin-bottom: $grid-gap-desktop * 2;
        }
      }

      &:last-of-type {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: $grid-gap-phone * 2;
  
        @include breakpoint-small {
          margin-bottom: $grid-gap-desktop * 4;
        }

        a {
          font-size: 12px;
          line-height: 14px;
          color: currentColor;
          text-decoration: underline;
          text-decoration-thickness: 1px;
          text-underline-offset: 2px;
    
          @include interaction-hover() {
            text-decoration: none;
          }
        }
      }
    }
  }

  input {
    all: unset;
    box-sizing: border-box;
    width: 100%;
    height: $grid-gap-phone * 1.8;
    margin-bottom: $grid-gap-phone * 0.5;
    padding: $grid-gap-phone * 0.2 $grid-gap-phone * 0.4;
    color: $black;
    background-color: $lightgray;
    border-radius: $grid-gap-phone * 0.1;

    &::placeholder {
      color: $darkgray;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition: background-color 0s 600000s, color 0s 600000s;
    }
  }

  .textArea {
    all: unset;
    box-sizing: border-box;
    width: 100%;
    min-height: 150px; // Adjust as needed for the text area
    margin-bottom: $grid-gap-phone * 0.5;
    padding: $grid-gap-phone * 0.2 $grid-gap-phone * 0.4;
    color: $black;
    background-color: $lightgray;
    border-radius: $grid-gap-phone * 0.1;
    resize: vertical; // Allows the user to resize the textarea vertically
    overflow: auto; // Ensures that scroll bars appear if the text exceeds the area

    &::placeholder {
      color: $darkgray;
    }

    &:focus {
      // Optionally, you can add a focus style
      outline: 2px solid darken($lightgray, 10%);
      outline-offset: 2px;
    }
  }

  button, label {
    margin: 0 auto;
  }
}