@use './variables' as *;
@use './functions' as *;
@use './mixins' as *;

html {
  height: 100%;
  scroll-behavior: smooth;
}

#root {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100svh;
  background-color: white;
}

main {
  flex: 1;
}

section {
  padding: $grid-gap-phone * 2 $grid-gap-phone;

  &:first-of-type {
    padding-top: $grid-gap-phone * 4;

    @include breakpoint-medium {
      padding-top: $grid-gap-phone * 6;
    }
  }

  @include breakpoint-small {
    padding: $grid-gap-phone * 4 $grid-gap-phone;
  }

  @include breakpoint-medium {
    padding: $grid-gap-phone * 6 $grid-gap-phone;
  }
}