@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;

.pageWrapper {
  display: grid;
  grid-template-columns: repeat($grid-columns-phone, 1fr);
  grid-template-rows: auto auto; // Define two rows, each will adjust to its content
  gap: $grid-gap-phone $grid-gap-phone;
  align-items: start;

  @include breakpoint-small {
    grid-template-columns: repeat($grid-columns-desktop, 1fr);
    grid-template-rows: auto auto; // Repeat row definition for desktop breakpoint
    gap: $grid-gap-desktop $grid-gap-desktop;
  }

  h2 {
    font-size: 1.2rem;
    letter-spacing: -0.01em;
    word-spacing: -0.01em;
  }

  .headlineWrapper {
    grid-row: 1; // Places headlineWrapper in the first row
    @include breakpoint-small {
      padding: $grid-gap-phone;
    }

    > h2 {
      font-size: 1.7rem;
      // Ensure the h2 takes up the full width of its container
      width: 100%;
    }
  }


  h3 {
    font-size: 1rem;
    letter-spacing: 0.01em;
  }

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: $grid-gap-phone;
    grid-column: 1 / calc($grid-columns-phone + 1);
    align-self: start;

    ul {
      list-style: none inside;

      margin-top: $grid-gap-phone;

      li {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: baseline;
        color: $black;

        &::before {
          content: '';
          font-size: 16px;
          line-height: 16px;
          color: $white;
          background-color: $orange;
          display: inline-flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-right: $grid-gap-phone * 0.5;
          padding: $grid-gap-phone * 0.2;
          border-radius: $grid-gap-phone;
        }

        &:not(:last-of-type) {
          margin-bottom: $grid-gap-phone;
        }
      }
    }

    a {
      margin-top: $grid-gap-phone;

      @include interaction-hover() {
        text-decoration: none;
      }
    }

    @include breakpoint-small {
      grid-column: 2 / calc($grid-columns-desktop);
    }
  }
}

.dienstleistungen {
  display: flex;
  gap: $grid-gap-phone;
  grid-row: 2; // Places dienstleistungen in the second row

  @include breakpoint-small {
    grid-template-columns: 1fr 3fr; // Maintains the two columns layout at larger breakpoints
    gap: $grid-gap-desktop;
  }

  .navList {
    flex: 3; // Assigns a flex value to control the width
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0;

    .navItem {
      color: $black;
      cursor: pointer;
      padding: $grid-gap-phone * 0.3; // Default padding for larger screens
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: $grid-gap-phone;

      // Adjust the breakpoint as needed for your design
      @include breakpoint-small {
        padding: $grid-gap-phone; // Decrease padding for mobile
      }

      &.active {
        background-color: $orange;
        color: $white;
      }

      &:hover {
        background-color: lighten($orange, 30%);
      }

      .arrowIcon {
        color: $white;
      }
    }

  }

  .contentArea {
    flex: 6; // Assigns a larger flex value for the content area
    @include breakpoint-small {
      padding: $grid-gap-phone;
    }
    h2 {
      margin-bottom: $grid-gap-phone;
      text-align: left;
    }
    p {
      color: $darkgray;
    }
  }
}

//.lineWithExtraSpacing {
//  margin-bottom: 0.5em; // or the desired spacing
//}