@use '../../styles/variables' as *;
@use '../../styles/functions' as *;
@use '../../styles/mixins' as *;

.products {
  display: grid;
  grid-template-columns: repeat($grid-columns-phone, 1fr);
  gap: $grid-gap-phone $grid-gap-phone;
  background-color: $lightgray-transparent;

  @include breakpoint-small {
    grid-template-columns: repeat($grid-columns-desktop, 1fr);
    gap: $grid-gap-desktop $grid-gap-desktop;
  }

  h2 {
    grid-column: 1 / calc($grid-columns-phone + 1);
    text-align: center;
    font-size: 1.7rem;
    //text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); // Optional text shadow for depth

    @include breakpoint-small {
      grid-column: 1 / calc($grid-columns-desktop + 1);
      margin-bottom: $grid-gap-desktop;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: $grid-gap-phone;
    grid-column: 1 / calc($grid-columns-phone + 1);
    align-self: start;

    ul {
      list-style: none inside;

      @include breakpoint-small {
        margin-top: $grid-gap-phone;
      }

      li {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: baseline;
        color: $black;

        &::before {
          content: '';
          font-size: 16px;
          line-height: 16px;
          color: $white;
          background-color: $orange;
          display: inline-flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-right: $grid-gap-phone * 0.5;
          padding: $grid-gap-phone * 0.2;
          border-radius: $grid-gap-phone;
        }

        &:not(:last-of-type) {
          margin-bottom: $grid-gap-phone;
        }
      }
    }

    a {
      margin-top: $grid-gap-phone;

      @include interaction-hover() {
        text-decoration: none;
      }
    }

    @include breakpoint-small {
      grid-column: 5 / calc($grid-columns-desktop);
    }
  }

  .item {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-self: start;
    grid-column: 1 / calc($grid-columns-phone + 1);
    background-color: white;
    padding: $grid-gap-phone;
    border-radius: $grid-gap-phone;
    gap: $grid-gap-phone $grid-gap-phone;

    &:hover .pagination::after {
      animation: none;
    }

    @include breakpoint-small {
      z-index: $z-index-low;
    }

    > .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: $grid-gap-phone * 0.5 $grid-gap-phone * 0.5;

      h3 {
        font-size: 20px;
        letter-spacing: 0.01em;
        line-height: 22px;
        //font-weight: bold;
      }

      p {
        height: 100%;
        color: $darkgray;
      }
    }

    > img {
      max-width: 100%; // Allows the image to scale responsively
      height: auto; // Maintains the aspect ratio
      border-radius: 0; // Logos usually don't need rounded corners
      overflow: visible; // Ensures the logo is fully visible
      background-color: transparent; // Clear background
      object-fit: contain; // Prevents distortion
      margin: auto; // Centers the logo horizontally within its flex container
      padding: $grid-gap-phone; // Adds padding around the logo
      box-shadow: none; // Removes any shadow effect
      // Removing the width: 100%; to allow the logo to maintain its original aspect ratio without forcibly stretching
    }

    @include breakpoint-small {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      gap: $grid-gap-phone $grid-gap-phone;
      grid-column: 3 / 9;

      > .text {
        grid-column: 1 / 7;
        gap: $grid-gap-desktop * 0.5 $grid-gap-desktop * 0.5;

        p {
          height: 100%;
        }
      }

      > img {
        grid-column: 7 / 9;
      }
    }

    @keyframes circularReveal {
      0% {
        clip-path: polygon(100% 50%, 100% 50%, 100% 50%);
      }
      6.25% {
        clip-path: polygon(100% 50%, 100% 45%, 100% 55%);
      }
      12.5% {
        clip-path: polygon(50% 50%, 100% 50%, 100% 0%);
      }
      18.75% {
        clip-path: polygon(50% 50%, 100% 50%, 100% 0%, 75% 0%);
      }
      25% {
        clip-path: polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%);
      }
      31.25% {
        clip-path: polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 25% 0%);
      }
      37.5% {
        clip-path: polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%);
      }
      43.75% {
        clip-path: polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 50%);
      }
      // Continue adding points around the circle
      50% {
        clip-path: polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 100%);
      }
      52% {
        clip-path: polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 100%, 26.25% 100%)
      }
      56.25% {
        clip-path: polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 100%, 50% 100%);
      }
      62.5% {
        clip-path: polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 100%, 50% 100%, 75% 100%);
      }
      68.75% {
        clip-path: polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 100%, 50% 100%, 100% 100%);
      }
      75% {
        clip-path: polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 100%, 50% 100%, 100% 100%, 100% 75%);
      }
      81.25% {
        clip-path: polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 100%, 50% 100%, 100% 100%, 100% 66.5%);
      }
      87.5% {
        clip-path: polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 100%, 50% 100%, 100% 100%, 100% 60%);
      }
      93.75% {
        clip-path: polygon(50% 50%, 100% 50%, 100% 0%, 50% 0%, 0% 0%, 0% 100%, 50% 100%, 100% 100%, 100% 50%, 50% 50%);
      }
      100% {
        clip-path: polygon(100% 50%, 100% 50%, 100% 50%);
      }
    }

    .pagination {
      position: relative;
      bottom: $grid-gap-phone;
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: $grid-gap-phone * 0.5;
      margin-top: $grid-gap-phone * 2;
      padding: $grid-gap-phone * 0.4;
      border-radius: $grid-gap-phone;
      background-color: $lightgray;
      border: 1px solid $darkgray;
      @media (max-width: 767px) {
        margin-left: auto;
        margin-right: auto;
      }

      &::after {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border: 2px solid $orange;
        border-radius: $grid-gap-phone;
        clip-path: polygon(100% 50%, 100% 50%, 100% 50%); // Start with a tiny pie piece
        z-index: 1;
      }
      @include breakpoint-small {
        justify-content: start;
      }
    }

    .dot {
      display: block;
      height: $grid-gap-phone * 0.4;
      width: $grid-gap-phone * 0.5;
      background-color: $darkgray;
      border-radius: $grid-gap-phone;
      transition: background-color $transition-medium $transition-easing;

      @include interaction-hover() {
        background-color: $orange-pale;
        cursor: pointer;
      }

      &.active {
        background-color: $orange;
      }
    }
  }
}
