@use '../styles/variables' as *;
@use '../styles/functions' as *;
@use '../styles/mixins' as *;

.signIn {
  margin-top: $grid-gap-phone;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $grid-gap-phone;
  text-align: center;
  position: relative; // Add this line

  h1 {
    font-size: 2.5rem;
    color: $black;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.1rem;
    color: $darkgray;
    margin-bottom: 2rem;
  }

  form {
    gap: $grid-gap-phone;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.waitingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $darkgray-transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000
}

.registrationSlider {
  display: flex;
  flex-direction: column;
  align-items: center; /* This will center the button horizontally */
  justify-content: start; /* Adjust this as needed, depending on your layout */
  overflow: hidden;
  max-height: 0;
}

.registrationSlider.open {
  max-height: 100%; /* Consider using a specific max-height value if 100% doesn't work */
}

// Additional style to hide/show elements inside the slider
.hideElement {
  display: none;
}

.showElement {
  display: block; // Or 'flex', 'inline', etc., depending on your layout
}
