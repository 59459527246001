/* Breakpoints */
$breakpoint-extrasmall: 360px;
$breakpoint-small: 768px;
$breakpoint-medium: 1024px;
$breakpoint-large: 1440px;
$breakpoint-extralarge: 1920px;

/* Layout */
$grid-columns-phone: 5;
$grid-columns-desktop: 10;
$grid-gap-phone: 20px;
$grid-gap-desktop: 20px;

$max-width: $breakpoint-extralarge;

$z-index-default: auto;
$z-index-background: -1;
$z-index-foreground: 100;
$z-index-low: 10;
$z-index-medium: 20;
$z-index-high: 30;

/* Transitions */
$transition-slow: 800ms;
$transition-medium: 400ms;
$transition-fast: 200ms;

$transition-easing: ease;

/* Colors */
$white: hsla(0, 0%, 99%, 1);
$black: hsla(0, 0%, 8%, 1);
$lightgray: hsla(0, 0%, 92%, 1);
$lightgray-transparent: hsla(0, 0%, 92%, 0.4);
$darkgray: hsla(0, 0%, 56%, 1);
$darkgray-transparent: hsla(0, 0%, 56%, 0.4);

$green: hsla(90, 62%, 50%, 1);
$green-pale: hsla(90, 62%, 80%, 1);
$blue: hsla(206, 100%, 50%, 1);
$blue-pale: hsla(206, 90%, 78%, 1);
$orange: hsla(20, 100%, 50%, 1);
$orange-half-pale: hsla(20, 100%, 50%, 1);
$orange-pale: hsla(20, 94%, 80%, 1);
$yellow: hsla(52, 100%, 54%, 1);
$yellow-pale: hsla(52, 94%, 80%, 1);
$cadet: hsl(245, 42%, 15%);
$cadet-pale: hsla(245, 42%, 15%, 0.3);

$color-primary: $black;
$color-secondary: $lightgray;
$color-tertiary: $orange;

$color-text: $color-primary;
$color-link: $color-tertiary;
$color-selection: $yellow-pale;
$color-media: $color-secondary;

/* Fonts */
$font-primary: 'ABC Diatype Regular Web', system-ui, sans-serif;
$font-secondary: 'ABC Diatype Bold Web', system-ui, sans-serif;